// extracted by mini-css-extract-plugin
export var blockquote = "Markdown-module--blockquote--jfHku";
export var h1 = "Markdown-module--h1--hba9E";
export var h2 = "Markdown-module--h2--fV7Ja";
export var h3 = "Markdown-module--h3--sQPL9";
export var h4 = "Markdown-module--h4--ZDRCk";
export var h5 = "Markdown-module--h5--pzO1q";
export var h6 = "Markdown-module--h6--nvU+v";
export var heading = "Markdown-module--heading--pKeDq";
export var hr = "Markdown-module--hr--R8aRu";
export var img = "Markdown-module--img--N8H1I";
export var link = "Markdown-module--link--lQG81";
export var selectable = "Markdown-module--selectable--IW4Uv";
export var unselectable = "Markdown-module--unselectable--joprR";